<template>
  <div class="flex justify-center mb-10">
    <div class="w-full lg:w-2/3 p-4 md:p-6">
      <h2 class="text-2xl md:text-3xl font-bold text-center dark:text-green-400 my-10">
        سطوح کاربری
      </h2>

      <div class="bg-gray-50 dark:bg-gray-800 leading-loose shadow rounded p-4 md:p-6 space-y-4">
        <div v-html="description"></div>

        <div class="flex flex-col">
          <div class="overflow-x-auto px-2">
            <div class="align-middle inline-block min-w-full py-3">
              <div class="shadow overflow-hidden border border-gray-800 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-800 border-collapse">
                  <thead class="divide-y divide-gray-800">
                    <tr class="divide-gray-800">
                      <th
                        class="table-head border-e border-gray-800"
                        rowspan="4"
                      >
                        سطح
                      </th>

                      <th
                        class="table-head"
                        colspan="9"
                      >
                        سقف واریز و برداشت
                      </th>
                    </tr>

                    <tr class="divide-s divide-gray-800">
                      <th
                        class="table-head"
                        colspan="3"
                      >
                        رمز ارز بر حسب تومان
                      </th>
                      <th
                        class="table-head"
                        colspan="3"
                      >
                        تومان
                      </th>
                      <th
                        class="table-head"
                        colspan="3"
                      >
                        رمز ارز و تومان در کل
                      </th>
                    </tr>

                    <tr class="divide-s divide-gray-800">
                      <th class="table-head">
                        واریز
                      </th>
                      <th
                        class="table-head"
                        colspan="2"
                      >
                        برداشت
                      </th>
                      <th class="table-head">
                        واریز
                      </th>
                      <th
                        class="table-head"
                        colspan="2"
                      >
                        برداشت
                      </th>
                      <th class="table-head">
                        واریز
                      </th>
                      <th
                        class="table-head"
                        colspan="2"
                      >
                        برداشت
                      </th>
                    </tr>

                    <tr class="divide-s divide-gray-800">
                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        ماهیانه
                      </th>

                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        ماهیانه
                      </th>

                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        روزانه
                      </th>
                      <th class="table-head">
                        ماهیانه
                      </th>
                    </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-800">
                    <tr
                      v-for="(level, lvlIdx) in levels"
                      :key="lvlIdx"
                      class="divide-s divide-gray-800 hover:bg-gray-100"
                    >
                      <td class="table-cell font-medium">
                        {{ lvlIdx }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.crypto?.deposit?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.crypto?.withdraw?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.crypto?.withdraw?.monthly) }}
                      </td>

                      <td class="table-cell">
                        {{ getLevelDetail(level.irt?.deposit?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.irt?.withdraw?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.irt?.withdraw?.monthly) }}
                      </td>

                      <td class="table-cell">
                        {{ getLevelDetail(level.irt_and_crypto?.deposit?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.irt_and_crypto?.withdraw?.daily) }}
                      </td>
                      <td class="table-cell">
                        {{ getLevelDetail(level.irt_and_crypto?.withdraw?.monthly) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoService from "@/services/InfoService";

export default {
  data() {
    return {
      description: "",
      levels: "",
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      InfoService.getUserLevels()
        .then(({ data }) => {
          console.log(data);
          this.description = data.description;
          this.levels = data.levels;
        });
    },
    getLevelDetail(item) {
      if (item === true)
        return "نامحدود";

      if (item === false)
        return "اجازه ندارد";

      if (!item || item.length === 0)
        return "-";

      if (!isNaN(item)) {
        return new Intl.NumberFormat().format(item);
      }

      return item;
    },
  },
};
</script>

<style>
.table-head {
  @apply text-center dark:bg-gray-700 font-semibold px-6 py-2;
}

.table-cell {
  @apply whitespace-nowrap dark:bg-gray-700 dark:text-gray-100 text-center text-sm px-6 py-4;
}

ul {
  @apply list-disc list-inside;
}
</style>
